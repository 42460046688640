import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Chart, Point, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { AkTransactionService } from 'src/app/modules/ak/services/ak-transaction.service';
import { AccountType } from 'src/app/modules/ak/shared/enums/AccountType';
import { formatPersonInfo } from 'src/app/modules/ak/shared/functions/getPersonAndOriginator';
import { GlobalEventsService } from 'src/app/services/global/global-events-service';
import { SplitNumberWithCommaPipe } from 'src/app/shared/pipes/split-number-with-comma.pipe';

@Component({
    selector: 'app-ak-profile-chart',
    templateUrl: './ak-profile-chart.component.html',
    styleUrls: ['./ak-profile-chart.component.scss'],
})
export class AkProfileChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() akProfile;
    @Input() isSmallGraph;
    @Output() updateCurrentBalanceEvent: EventEmitter<string> = new EventEmitter();

    constructor(private akTransactionService: AkTransactionService, private globalEventsService: GlobalEventsService) {}
    person;
    transactions;
    currentBalance;
    myChart;
    filtering;
    paging = {
        pageSize: 50,
    };

    sorting = {
        sortColumn: 'createdAt',
        sortDirection: 'DESC',
    };

    ngOnDestroy() {
        this.myChart?.destroy();
    }

    ngOnInit(): void {
        Chart.register(...registerables);
    }

    ngOnChanges(changes) {
        if (changes.akProfile.currentValue) {
            this.getTransactions(this.akProfile).subscribe((transactions) => this.createChart(transactions));
        }
    }

    getAkProfile() {
        return this.globalEventsService.akProfile$;
    }

    getTransactions(akProfile) {
        const today = new Date();
        const dateFrom = new Date(today);
        dateFrom.setFullYear(today.getFullYear() - 1);
        this.person = formatPersonInfo(akProfile);
        this.filtering = {
            accountType: AccountType.PERSONAL,
            personId: this.person.personId,
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment().format('YYYY-MM-DD'),
            vsnum: this.person?.insurances[0]?.vsNum,
        };

        return this.akTransactionService.getTransactions(this.paging, this.sorting, this.filtering);
    }

    createChart(transactions) {
        if (this.myChart) this.myChart.destroy();
        this.transactions = transactions.content.sort((a, b) => {
            this.currentBalance = transactions.content[0]?.runningBalance;
            this.updateCurrentBalanceEvent.emit(this.currentBalance);

            if (a.pvClanBookingId < b.pvClanBookingId) {
                return -1;
            }
            if (a.pvClanBookingId > b.pvClanBookingId) {
                return 1;
            }
            return 0;
        });
        this.transactions = transactions.content.filter((x) => x.runningBalance);

        const labels = this.transactions.map((transaction) => transaction.createdAt.toString());
        const data = this.transactions.map((transaction) => ({
            x: transaction.createdAt,
            y: transaction?.runningBalance,
        }));

        const ctx = document.getElementById('homePageChart') as HTMLCanvasElement;
        const splitNumberWithCommaPipe = new SplitNumberWithCommaPipe();

        this.myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        borderColor: 'grey',
                        backgroundColor: 'rgba(200, 200, 200, 0.5)',
                        borderWidth: 1,
                        fill: true,
                    },
                ],
            },
            options: {
                elements: {
                    point: {
                        radius: this.isSmallGraph ? 0 : 3,
                    },
                },
                scales: {
                    x: {
                        display: false,
                        ticks: {
                            callback: (value: number, index: number, values: any[]) => {
                                const date = new Date(value);
                                let previousDate = { value: '1900' };
                                if (values[index - 1]) {
                                    previousDate = JSON.parse(JSON.stringify(values[index - 1]));
                                }
                                if (index === 0 || new Date(previousDate.value).getFullYear() !== date.getFullYear()) {
                                    return date.getFullYear(); // Show the year for the first data point or when the year changes
                                }
                            },
                        },
                        type: 'time',
                        time: {
                            unit: 'day',
                        },
                        grid: {
                            display: false,
                        },
                        title: {
                            display: false,
                            text: 'Date',
                        },
                    },
                    y: {
                        beginAtZero: false, // Modify as needed
                        grid: {
                            color: 'rgba(200, 200, 200, 0.5)',
                            display: true,
                        },
                        title: {
                            display: false,
                            text: 'Balance',
                        },
                        ticks: {
                            callback: (value, index, values) => {
                                // Use your custom pipe to format the label
                                return splitNumberWithCommaPipe.transform(value);
                            },
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.dataset.data[context.dataIndex] as Point;
                                let labelValue = splitNumberWithCommaPipe.transform(label.y, true);
                                return labelValue;
                            },
                        },
                    },
                },
            },
        });
    }
}
